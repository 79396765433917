import { default as contact8zxLAXYQX4Meta } from "/workspace/pages/contact.vue?macro=true";
import { default as discountIdFrSA5ZAV26Meta } from "/workspace/pages/discount/discountId.vue?macro=true";
import { default as _91comparisonId_93qHtJ8O0vjpMeta } from "/workspace/pages/guide/[comparisonId].vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as _91interstitialId_937nMp8fLIxFMeta } from "/workspace/pages/limited-offer/[interstitialId].vue?macro=true";
import { default as privacy_45policyJuUuuPjb3dMeta } from "/workspace/pages/privacy-policy.vue?macro=true";
import { default as terms_45and_45conditionsD3pGvh9rLRMeta } from "/workspace/pages/terms-and-conditions.vue?macro=true";
import { default as _91listicleId_93PY4VkRZ9QcMeta } from "/workspace/pages/todays-deals/[listicleId].vue?macro=true";
import { default as _91trendingId_93DZ30V4ggFtMeta } from "/workspace/pages/trending/[trendingId].vue?macro=true";
export default [
  {
    name: contact8zxLAXYQX4Meta?.name ?? "contact",
    path: contact8zxLAXYQX4Meta?.path ?? "/contact",
    meta: contact8zxLAXYQX4Meta || {},
    alias: contact8zxLAXYQX4Meta?.alias || [],
    redirect: contact8zxLAXYQX4Meta?.redirect,
    component: () => import("/workspace/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: discountIdFrSA5ZAV26Meta?.name ?? "discount-discountId",
    path: discountIdFrSA5ZAV26Meta?.path ?? "/discount/discountId",
    meta: discountIdFrSA5ZAV26Meta || {},
    alias: discountIdFrSA5ZAV26Meta?.alias || [],
    redirect: discountIdFrSA5ZAV26Meta?.redirect,
    component: () => import("/workspace/pages/discount/discountId.vue").then(m => m.default || m)
  },
  {
    name: _91comparisonId_93qHtJ8O0vjpMeta?.name ?? "guide-comparisonId",
    path: _91comparisonId_93qHtJ8O0vjpMeta?.path ?? "/guide/:comparisonId()",
    meta: _91comparisonId_93qHtJ8O0vjpMeta || {},
    alias: _91comparisonId_93qHtJ8O0vjpMeta?.alias || [],
    redirect: _91comparisonId_93qHtJ8O0vjpMeta?.redirect,
    component: () => import("/workspace/pages/guide/[comparisonId].vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91interstitialId_937nMp8fLIxFMeta?.name ?? "limited-offer-interstitialId",
    path: _91interstitialId_937nMp8fLIxFMeta?.path ?? "/limited-offer/:interstitialId()",
    meta: _91interstitialId_937nMp8fLIxFMeta || {},
    alias: _91interstitialId_937nMp8fLIxFMeta?.alias || [],
    redirect: _91interstitialId_937nMp8fLIxFMeta?.redirect,
    component: () => import("/workspace/pages/limited-offer/[interstitialId].vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyJuUuuPjb3dMeta?.name ?? "privacy-policy",
    path: privacy_45policyJuUuuPjb3dMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyJuUuuPjb3dMeta || {},
    alias: privacy_45policyJuUuuPjb3dMeta?.alias || [],
    redirect: privacy_45policyJuUuuPjb3dMeta?.redirect,
    component: () => import("/workspace/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsD3pGvh9rLRMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsD3pGvh9rLRMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsD3pGvh9rLRMeta || {},
    alias: terms_45and_45conditionsD3pGvh9rLRMeta?.alias || [],
    redirect: terms_45and_45conditionsD3pGvh9rLRMeta?.redirect,
    component: () => import("/workspace/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: _91listicleId_93PY4VkRZ9QcMeta?.name ?? "todays-deals-listicleId",
    path: _91listicleId_93PY4VkRZ9QcMeta?.path ?? "/todays-deals/:listicleId()",
    meta: _91listicleId_93PY4VkRZ9QcMeta || {},
    alias: _91listicleId_93PY4VkRZ9QcMeta?.alias || [],
    redirect: _91listicleId_93PY4VkRZ9QcMeta?.redirect,
    component: () => import("/workspace/pages/todays-deals/[listicleId].vue").then(m => m.default || m)
  },
  {
    name: _91trendingId_93DZ30V4ggFtMeta?.name ?? "trending-trendingId",
    path: _91trendingId_93DZ30V4ggFtMeta?.path ?? "/trending/:trendingId()",
    meta: _91trendingId_93DZ30V4ggFtMeta || {},
    alias: _91trendingId_93DZ30V4ggFtMeta?.alias || [],
    redirect: _91trendingId_93DZ30V4ggFtMeta?.redirect,
    component: () => import("/workspace/pages/trending/[trendingId].vue").then(m => m.default || m)
  }
]