import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_03YjkxYbK5 from "/workspace/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import markdownit_fsFV40uZ9C from "/workspace/plugins/markdownit.ts";
import sentry_3AyO8nEfhE from "/workspace/plugins/sentry.ts";
import tagparser_K4HlwUv7F7 from "/workspace/plugins/tagparser.ts";
import vue_gtm_client_Cm24Dsc5BN from "/workspace/plugins/vue-gtm.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_03YjkxYbK5,
  chunk_reload_client_UciE0i6zes,
  markdownit_fsFV40uZ9C,
  sentry_3AyO8nEfhE,
  tagparser_K4HlwUv7F7,
  vue_gtm_client_Cm24Dsc5BN
]