import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((NuxtApp) => {
    NuxtApp.vueApp.use(
        createGtm({
            id: 'GTM-5FQ27CV',
            defer: true,
            enabled: true,
            debug: false,
            loadScript: true,
            vueRouter: useRouter(),
            trackOnNextTick: true,
        }))
})